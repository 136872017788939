<template>
  <div class="p-4">
    <h2 class="text-2xl font-semibold mb-4">Editar Escort</h2>

    <form @submit.prevent="updateEscort">

      <!-- Sección 1: Datos Principales -->
      <h3 class="text-xl font-semibold mb-2">Datos Principales</h3>
      <label class="block mb-2">
        Nombre:
        <input v-model="escortData.name" type="text" class="w-full p-2 border rounded mb-2" />
      </label>

      <label class="block mb-2">
        Teléfono:
        <input v-model="escortData.phone" type="text" class="w-full p-2 border rounded mb-2" disabled />
      </label>

      <label class="block mb-2">
        Descripción:
        <textarea v-model="escortData.description" class="w-full p-2 border rounded mb-2"></textarea>
      </label>

      <label class="block mb-2">
        Edad:
        <input v-model="escortData.age" type="number" class="w-full p-2 border rounded mb-2" />
      </label>

      <label class="block mb-2">
        Color de pelo:
        <input v-model="escortData.hair" type="text" class="w-full p-2 border rounded mb-2" />
      </label>

      <label class="block mb-2">
        Altura (cm):
        <input v-model="escortData.height" type="number" class="w-full p-2 border rounded mb-2" />
      </label>

      <label class="block mb-2">
        Peso (kg):
        <input v-model="escortData.weight" type="number" class="w-full p-2 border rounded mb-2" />
      </label>

      <label class="block mb-2">
        Nacionalidad:
        <input v-model="escortData.nationality" type="text" class="w-full p-2 border rounded mb-2" />
      </label>

      <label class="block mb-2">
        1H:
        <input v-model="escortData.una_hora" type="text" class="w-full p-2 border rounded mb-2" />
      </label>

      <label class="block mb-2">
	30m
        <input v-model="escortData.media_hora" type="text" class="w-full p-2 border rounded mb-2" />
      </label>

      <!-- Sección 2: Ciudad -->
      <h3 class="text-xl font-semibold mb-2 mt-4">Ciudad</h3>
      <label class="block mb-2">
        Ciudad:
        <input v-model="escortData.city.name" type="text" class="w-full p-2 border rounded mb-2" disabled />
      </label>
      <label class="block mb-2">
        Provincia:
        <input v-model="escortData.city.province_id" type="number" class="w-full p-2 border rounded mb-2" />
      </label>

      <!-- Sección 3: Fotos Secundarias -->
      <h3 class="text-xl font-semibold mb-2 mt-4">Fotos Secundarias</h3>
      <div v-for="(photo) in escortData.secondary_photos" :key="photo.id" class="mb-2 flex items-center">
        <input v-model="photo.url" type="text" class="w-full p-2 border rounded mb-2" />
        <button type="button" @click="removePhoto(photo.id)" class="bg-red-500 text-white px-4 py-2 ml-2 rounded">
          Eliminar
        </button>
      </div>

      <!-- Sección 4: Idiomas -->
      <h3 class="text-xl font-semibold mb-2 mt-4">Idiomas</h3>
      <div v-for="(language, index) in escortData.languages" :key="language.id" class="mb-2">
        <label class="block">
          Idioma {{ index + 1 }}:
          <input v-model="language.name" type="text" class="w-full p-2 border rounded mb-2" />
        </label>
      </div>

      <!-- Sección 5: Verificaciones y Popularidad -->
      <h3 class="text-xl font-semibold mb-2 mt-4">Verificaciones y Popularidad</h3>
      <label class="block mb-2">
        Verificación en persona:
        <input v-model="escortData.in_person_verified" type="checkbox" class="mr-2" />
      </label>
      <label class="block mb-2">
        Es popular:
        <input v-model="escortData.is_popular" type="checkbox" class="mr-2" />
      </label>
      <label class="block mb-2">
        Fotos verificadas:
        <input v-model="escortData.photos_verified" type="checkbox" class="mr-2" />
      </label>

      <!-- Botones de acción -->
      <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded mt-4">Actualizar</button>
      <button type="button" @click="deleteEscort" class="bg-red-500 text-white px-4 py-2 rounded mt-4 ml-2">Eliminar</button>
    </form>
  </div>
</template>

<script>
import axiosInstance from '@/axiosInstance';

export default {
  name: 'EscortDetailView',
  props: ['phone'],
  data() {
    return {
      escortData: null,
    };
  },
  async created() {
    try {
      const response = await axiosInstance.get(`/escort/${this.phone}`);
      this.escortData = response.data;
    } catch (error) {
      console.error('Error al obtener los datos de la escort:', error);
    }
  },
  methods: {
    async updateEscort() {
      try {
        await axiosInstance.put(`/escort/${this.phone}`, this.escortData);
        alert('Datos actualizados correctamente');
      } catch (error) {
        console.error('Error al actualizar los datos:', error);
        alert('Error al actualizar los datos');
      }
    },
    async deleteEscort() {
      const confirmDelete = confirm('¿Estás seguro de que deseas eliminar esta escort?');
      if (confirmDelete) {
        try {
          await axiosInstance.delete(`/escort/${this.phone}`);
          alert('Escort eliminada correctamente');
          this.$router.push('/escorts/list');
        } catch (error) {
          console.error('Error al eliminar la escort:', error);
          alert('Error al eliminar la escort');
        }
      }
    },
    async removePhoto(photoId) {
      const confirmDelete = confirm('¿Estás seguro de que deseas eliminar esta foto?');
      if (confirmDelete) {
        try {
          // Llamada a la API para eliminar la foto individualmente si existe el endpoint, o para eliminar de la lista en `escortData`
          await axiosInstance.delete(`/escort/${this.phone}/photo/${photoId}`);
          this.escortData.secondary_photos = this.escortData.secondary_photos.filter(photo => photo.id !== photoId);
          alert('Foto eliminada correctamente');
        } catch (error) {
          console.error('Error al eliminar la foto:', error);
          alert('Error al eliminar la foto');
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
