"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'LoginView',
    setup: function () {
        var username = (0, vue_1.ref)('');
        var password = (0, vue_1.ref)('');
        var error = (0, vue_1.ref)('');
        var handleLogin = function () {
            if (username.value === 'admin' && password.value === 'admin123') {
                alert('Login successful!');
                error.value = '';
            }
            else {
                error.value = 'Invalid username or password';
            }
        };
        return {
            username: username,
            password: password,
            error: error,
            handleLogin: handleLogin,
        };
    }
});
