"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = render;
var vue_1 = require("vue");
var _hoisted_1 = { class: "min-h-screen flex items-center justify-center bg-gray-100" };
var _hoisted_2 = { class: "bg-white p-8 rounded-lg shadow-md w-full max-w-sm" };
var _hoisted_3 = { class: "mb-4" };
var _hoisted_4 = { class: "mb-4" };
var _hoisted_5 = {
    key: 0,
    class: "mt-4 text-red-500 text-center"
};
function render(_ctx, _cache, $props, $setup, $data, $options) {
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_1, [
        (0, vue_1.createElementVNode)("div", _hoisted_2, [
            _cache[6] || (_cache[6] = (0, vue_1.createElementVNode)("h2", { class: "text-2xl font-bold text-center text-gray-700 mb-6" }, "Login", -1)),
            (0, vue_1.createElementVNode)("form", {
                onSubmit: _cache[2] || (_cache[2] = (0, vue_1.withModifiers)(
                //@ts-ignore
                function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    return (_ctx.handleLogin && _ctx.handleLogin.apply(_ctx, args));
                }, ["prevent"]))
            }, [
                (0, vue_1.createElementVNode)("div", _hoisted_3, [
                    _cache[3] || (_cache[3] = (0, vue_1.createElementVNode)("label", {
                        for: "username",
                        class: "block text-sm font-medium text-gray-700 mb-2"
                    }, "Username", -1)),
                    (0, vue_1.withDirectives)((0, vue_1.createElementVNode)("input", {
                        type: "text",
                        id: "username",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((_ctx.username) = $event); }),
                        class: "w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500",
                        placeholder: "Enter your username"
                    }, null, 512), [
                        [vue_1.vModelText, _ctx.username]
                    ])
                ]),
                (0, vue_1.createElementVNode)("div", _hoisted_4, [
                    _cache[4] || (_cache[4] = (0, vue_1.createElementVNode)("label", {
                        for: "password",
                        class: "block text-sm font-medium text-gray-700 mb-2"
                    }, "Password", -1)),
                    (0, vue_1.withDirectives)((0, vue_1.createElementVNode)("input", {
                        type: "password",
                        id: "password",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((_ctx.password) = $event); }),
                        class: "w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500",
                        placeholder: "Enter your password"
                    }, null, 512), [
                        [vue_1.vModelText, _ctx.password]
                    ])
                ]),
                _cache[5] || (_cache[5] = (0, vue_1.createElementVNode)("button", {
                    type: "submit",
                    class: "w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition"
                }, " Login ", -1))
            ], 32),
            (_ctx.error)
                ? ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_5, (0, vue_1.toDisplayString)(_ctx.error), 1))
                : (0, vue_1.createCommentVNode)("", true)
        ])
    ]));
}
