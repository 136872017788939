<template>
  <div class="max-w-4xl mx-auto py-10 px-6 bg-white shadow-lg rounded-lg">
    <h1 class="text-2xl font-bold mb-6 text-gray-800">Crear Artículo</h1>
    <form @submit.prevent="submitArticle">
      <!-- Título -->
      <div class="mb-4">
        <label for="title" class="block text-sm font-medium text-gray-700">Título</label>
        <input
          type="text"
          id="title"
          v-model="article.title"
          class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="Escribe el título del artículo"
          required
        />
      </div>

      <!-- Slug -->
      <div class="mb-4">
        <label for="slug" class="block text-sm font-medium text-gray-700">Slug</label>
        <input
          type="text"
          id="slug"
          v-model="article.slug"
          class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="URL amigable del artículo"
          required
        />
      </div>

      <!-- Contenido -->
      <div class="mb-6">
        <label for="content" class="block text-sm font-medium text-gray-700">Contenido</label>
        <div id="editor" class="mt-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm min-h-[200px]"></div>
      </div>

	<!-- Sección de Imágenes -->
	<div class="mb-4">
	  <label class="block text-sm font-medium text-gray-700">Imágenes</label>
	  <div v-for="(image, index) in article.images" :key="index" class="mt-2">
	    <input
	      type="url"
	      v-model="article.images[index]"
	      class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
	      placeholder="URL de la imagen"
	      @input="addImageInput(index)"
	    />
	  </div>
	</div>


	<!-- Provincia -->
	<div class="mb-4">
	  <label for="province_id" class="block text-sm font-medium text-gray-700">Provincia</label>
	  <select
	    id="province_id"
	    v-model="article.province_id"
	    class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
	    required
	  >
	    <option value="" disabled>Selecciona una provincia</option>
	    <option v-for="province in provinces" :key="province.id" :value="province.id">
	      {{ province.name }}
	    </option>
	  </select>
	</div>


      <!-- Categoría -->
      <div class="mb-4">
        <label for="category_id" class="block text-sm font-medium text-gray-700">Categoría</label>
        <select
          id="category_id"
          v-model="article.category_id"
          class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        >
          <option value="" disabled>Selecciona una categoría</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
      </div>

      <!-- Teléfono del escort -->
      <div class="mb-4">
        <label for="escort_phone" class="block text-sm font-medium text-gray-700">Teléfono del escort</label>
        <input
          type="text"
          id="escort_phone"
          v-model="article.escort_phone"
          class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="Teléfono del escort"
          required
        />
      </div>

      <!-- Botón de envío -->
      <div class="text-right">
        <button
          type="submit"
          class="px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg shadow hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Guardar Artículo
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axiosInstance from "@/axiosInstance"; // Usa tu instancia configurada de Axios
import Quill from "quill";
import "quill/dist/quill.snow.css";

export default {
  data() {
    return {
      article: {
        title: "",
        slug: "",
        content: "",
	images: [""],
	province_id: null,
        escort_phone: "",
      },
      provinces: [],
    };
  },
  methods: {
	addImageInput(index) {
	   if (this.article.images[index] && index === this.article.images.length - 1) {
		   this.article.images.push("");
	   }
	},
    async fetchProvinces() {
      try {
        const response = await axiosInstance.get("/provinces");
        this.provinces = response.data;
      } catch (error) {
        console.error("Error al obtener las provincias:", error);
        alert("No se pudieron cargar las provincias.");
      }
    },
    async submitArticle() {
      try {
        const editorContent = this.quillEditor.root.innerHTML;
        this.article.content = editorContent;

        const response = await axiosInstance.post("/articles", this.article);
        alert("Artículo creado exitosamente");
        console.log("Respuesta de la API:", response.data);
      } catch (error) {
        console.error("Error al crear el artículo:", error);
        alert("Error al crear el artículo. Por favor, revisa los datos e inténtalo de nuevo.");
      }
    },
  },
  mounted() {
    this.fetchProvinces();

    this.quillEditor = new Quill("#editor", {
      theme: "snow",
      placeholder: "Escribe el contenido del artículo aquí...",
    });
  },
};

</script>


<style scoped>
.min-h-[200px] {
  min-height: 200px;
}
</style>
