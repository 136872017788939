<template>
  <div class="p-4">
    <h2 class="text-2xl font-semibold mb-4">Listado de Escorts</h2>
    <div class="grid grid-cols-2 gap-4 sm:grid-cols-2">
      <div 
        v-for="escort in dataFromAPI" 
        :key="escort.phone" 
        class="bg-white border rounded-lg p-4 shadow-md cursor-pointer transform hover:scale-105 transition duration-200"
        @click="goToEscort(escort.phone)"
      >
        <h3 class="text-lg font-medium text-gray-800">{{ escort.name }}</h3>
        <img :src="escort.secondary_photos[0]?.url" alt="Foto principal" class="w-full h-auto rounded mt-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import axiosInstance from '@/axiosInstance';

export default defineComponent({
  name: 'ListEscortsView',
  data() {
    return {
      dataFromAPI: [], // Inicializamos dataFromAPI como un array vacío
    };
  },
  async created() {
    try {
      const response = await axiosInstance.get('/escorts/list');
      this.dataFromAPI = response.data;
    } catch (error) {
      console.error('Error al llamar a la API:', error);
    }
  },
  methods: {
    goToEscort(phone) {
      this.$router.push(`/escort/${phone}`);
    },
  },
});
</script>

<style scoped>
/* No necesitas CSS adicional aquí, ya que Tailwind CSS maneja el estilo */
</style>
