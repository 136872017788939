// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import ListEscortsView from '@/views/ListEscortsView.vue';
import EscortDetailView from '@/views/EscortDetailView.vue';
import CreateArticleView from "@/views/CreateArticleView.vue";

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/list',
    name: 'ListEscortsView',
    component: ListEscortsView
  },
  {
    path: '/escort/:phone',
    name: 'EscortDetail',
    component: EscortDetailView,
    props: true,
  },
  {
    path: "/articles/create",
    name: "CreateArticle",
    component: CreateArticleView,
  },
];

const router = createRouter({
  history: createWebHistory(), // Puedes cambiar esto a createWebHashHistory() si prefieres usar hash mode
  routes
});

export default router;
