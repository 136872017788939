"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = render;
var vue_1 = require("vue");
var _hoisted_1 = { id: "app" };
function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_router_view = (0, vue_1.resolveComponent)("router-view");
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", _hoisted_1, [
        (0, vue_1.createVNode)(_component_router_view)
    ]));
}
